import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { previous, next } = pageContext
  const { frontmatter, html, fields } = markdownRemark

  const nextArticle = next && (
    <Link to={next.frontmatter.path}>
      <b>Previous</b><br />
      {next.frontmatter.title}
    </Link>
    )
    
  const prevArticle = previous && (
    <Link to={previous.frontmatter.path}>
      <b>Next</b><br />
      {previous.frontmatter.title}
    </Link>
  )
  const renderTag = tag => <span key={tag}><Link to={`/tags/${tag}`}>{tag}</Link> </span>;

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="container py-4 py-md-5">
        <div className="row">
          <article className="col col-lg-8 markdown-article">
            <header className="mb-5 article-header">
              <h1 className="mb-0">{frontmatter.title}</h1>
              <p className="text-muted">{frontmatter.date} • {fields.readingTime.text}</p>
            </header>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {frontmatter.tags && frontmatter.tags.length && (
              <footer className="text-muted small">
                Filed under {frontmatter.tags.map(tag => renderTag(tag))}
              </footer>
            )}
            <footer>
              {(prevArticle || nextArticle) && (
              <div className="mt-5 row pagination">
                <div className="col">
                  {nextArticle}
                </div>
                <div className="col text-right">
                  {prevArticle}
                </div>
              </div>
              )}
            </footer>
              
          </article>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
      }
    }
  }
`
